import React, { useEffect } from "react"
import { graphql } from "gatsby"
import {
  Container,
} from '@chakra-ui/react'
import Layout from "../components/Layout"
import Footer from '../components/Footer'
import SEO from "../components/SEO"
import { H1 } from '../theme/Headings'
import { spacing, breakpoints, colors } from '../theme/themeAlopex'
import PlainText from '../components/CaseStudies/PlainText'
import PlainImage from '../components/CaseStudies/PlainImage'
import ImageText from '../components/CaseStudies/ImageText'
import Columns from '../components/CaseStudies/Columns'
// gsap
import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger'
// Register gsap plugins
gsap.registerPlugin(ScrollTrigger)

const MidnightPage = ({ data }) => {
  // gsap's scrollTrigger for fade-up
  const gsapFadeUp = (csBlocks) => {
    for (let i = 0; i < csBlocks.length; ++i) {
      gsap.from(csBlocks[i], {
        scrollTrigger: {
          trigger: csBlocks[i],
          start: 'top 75%',
          // marker: true
        },
        duration: 1,
        y: 50,
        opacity: 0,
      })
    }
  }
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const csBlocks = document.querySelectorAll('.csBlock')
      gsapFadeUp(csBlocks)
    }
  }, [])

  return (
    <Layout>
      <SEO title={`Midnight Sun Oncology Website - ${data.site.siteMetadata.title}`} noIndex />
      <Container maxWidth={breakpoints.xl} pt={spacing.header}>
        <H1 color={colors.brand.slate} textAlign="center" mt={spacing.header} mb={spacing.groups}>Midnight Sun Oncology Website</H1>
      </Container>
      {/** BLOCK 01 */}
      <ImageText
        heading="Brief"
        body="Midnight Sun Oncology (MSO) presented and interesting challenge to Alopex for the reason that readers who navigate to MSO
        really don’t want to be there. Instead of trying to fight this, Alopex counseled the client that the goal for the website should
        be simple presentation of complex information and a sense of reassurance."
        img={data.yourmommasmomma.childImageSharp.fluid}
        color={colors.brand.slate}
        overlayColor="white"
        overlayOpacity="0"
      />
      {/** BLOCK 02 */}
      <PlainText
        color={colors.brand.lightGray}
        bgColor="#fcb900"
        heading="Design"
        body="MSO was designed with two primary functions; to give a short overview of the treatment of cancers, and to present Midnight Sun
        Oncology and its staff to the user. MidnightSunOncology.com is one of the more visually simple websites that Alopex has developed—and
        that is because much of the time was spent ironing out content and condensing sections into a much more web-friendly form, where
        people are hesitant to read long paragraphs. Straight-forward navigation, sweeping landscapes, and clever condensation of information
        make the website very readable on any device and approachable. A palate of cyans, very light grays and white imbues the site with a
        friendly but professional feeling."
      />
      {/** BLOCK 03 */}
      <Columns
        blockLeft={
          <PlainImage img={data.screeny.childImageSharp.fluid} alt="Screenshot" size="small" />
        }
        blockRight={
          <PlainImage img={data.eeny.childImageSharp.fluid} alt="Internal page" size="small" />
        }
      />
      {/** BLOCK 05 */}
      <Columns
        blockLeft={
          <PlainText
            color={colors.brand.slate}
            size="small"
            bgColor="#fff"
            heading="Development"
            body="MidnightSunOncology.com is a WordPress website that has been incredibly optimized for responsiveness. Because of the very limited
            number of assets on the website, Alopex was able to hyper compress many of the images, meaning a snappy load time. The use of ‘repeating
            fields’ in this custom-built theme allows information to be organized into accordion-style interactions on the treatments page—a unique
            method of presenting this information. As with all Alopex websites, MSO is fully integrated with Google Analytics and developed only with
            tools that are parsable by search engines."
          />
        }
        blockRight={
          <PlainImage img={data.teeny.childImageSharp.fluid} alt="Mobile responsive" size="small" />
        }
      />
      {/** BLOCK 07 */}
      <PlainText
        color={colors.brand.lightGray}
        bgColor="#0693e3"
        heading="Results"
        className="lastBlock"
        body="A comparison with any of the other existing sites in MSO’s field makes it obvious that MidnightSunOncology.com is highly local, utilizing
        local images, approachable photography, and purposefully written content without jargon or waffling. This is, of course, due to the fact that
        this website was designed and built specifically for MSO by Alopex. Even without Search Engine Marketing (SEM), MSO enjoys a top-five result in
        most applicable searches. MidnightSunOncology.com is an excellent example of a high design site that is simple to use and does the job well."
      />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query midnightPage {
    site {
      siteMetadata {
        title
      }
    }
    yourmommasmomma: file(relativePath: { eq: "casestudies/midnight/Rectangle-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    screeny: file(relativePath: { eq: "casestudies/midnight/ALO-Insta_MS-1-3.png" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    eeny: file(relativePath: { eq: "casestudies/midnight/ALO-Insta_MS-2-3.png" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teeny: file(relativePath: { eq: "casestudies/midnight/Group-6-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default MidnightPage
